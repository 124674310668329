body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navlinkStyle{
  color: #000 !important;
  text-decoration: none !important;
  font-family: 'poppins-light';
  text-shadow: 0 1px 0 rgb(0 0 0 / 5%);
  display: block;
  padding: .5rem 1rem;
}
.hoverBlue:hover,.hoverBlue:focus{
  color:#28b8dc !important;
}
@media (min-width:320px)and (max-width:578px){
    #responsive-navbar-nav{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
}
@media (min-width:320px)and (max-width:450px){
     .BrandTitle{
       margin-left:30%!important;
     }
}
.btn{
color: #000;
}
.btn:hover{
  background-color: #000;
  color:white;
}