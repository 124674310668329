

:root{
  --rotateCount:1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.triangle {
  position: relative;
}

.headeMenu{
  list-style-type: none;
}
/* .workLogoSpin{
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
} */
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.workLogoSpin2{
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  /* -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear;
  animation-iteration-count:var(--rotateCount) */
}
.businessBox:hover .workLogoSpin2{
/* background-color: rgb(64, 143, 64); */
animation-duration: 500ms;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear;
  animation-iteration-count:var(--rotateCount)
}
.text-muted{
  color:#b3b3b3!important;
}
/* Strategies styling start */
@media (min-width:300px) and (max-width:578px) { 
  .stratigy1{
    width: 80% !important;
    margin-bottom:1.5rem!important;
  }
  .stratigy2{
    width: 100% !important;
    margin-bottom:1.5rem!important;
    display: flex;
    justify-content: center ;
    align-items: center;
  }
  .stratigy2text{
    margin: 0 15% !important;
  }
  /* About Styling Start*/
  .businessBox{
    width:100%!important;
    padding-top:0px!important;
    padding-bottom:0px!important;
  }
  .workLogoSpin2{
    width:25%!important;
  }
  .aboutText{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0px 25px 0px 25px!important;
  }
  .aboutText h5{
    margin-bottom: 1rem!important;
  }
  .mainAboutBox{
    padding-bottom: 20px!important;
  }
  /* About Styling end */
  /* Team Styling Start */
   .consultingMainRow{
    height:50vh!important;
   }  
   .mobileteam{
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* padding:0px 25px 0px 25px!important; */
   }
   .teampaddingremove{
    padding: 0px !important;
   }
   .marginremove{
    margin-bottom: 2rem !important;
   }
   .headeradvice{
    font-size:1.7rem !important;
   }

  /* Team Styling end */
  /* Contact Styling */
  .mobileContactHead{
    margin-left: 1rem!important;
    margin-top: -2rem!important;
    margin-bottom: 1rem!important;
  }
}
/* Strategies styling end */